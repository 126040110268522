// MUI COMPONENTS
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// SCSSS
import './CustomAccordion.scss';

const CustomAccordion = ({ title, children }) => {
    return (
        <div className="accordion__single__container">
            <Accordion className="accordion__single">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    className="accordion__pannel"
                >
                    <h3 className="accordion-title">{title}</h3>
                </AccordionSummary>
                <AccordionDetails className="accordion__text">{children}</AccordionDetails>
            </Accordion>
        </div>
    );
};

export default CustomAccordion;
