import ReactQuill, { Quill } from 'react-quill';
import ImageUploader from 'quill-image-uploader';

import { uploadFile } from 'api/services/posts';

import './QuillEditor.scss';
import 'react-quill/dist/quill.snow.css';

const quillModules = {
    toolbar: {
        container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ align: [] }],
            ['link', 'image'],
            ['clean'],
            [{ color: [] }],
        ],
    },
    imageUploader: {
        upload: (file) => {
            return new Promise((resolve, reject) => {
                const formData = new FormData();
                formData.append('file', file);
                uploadFile(formData)
                    .then((result) => {
                        resolve(result.url);
                    })
                    .catch((error) => {
                        reject('Upload failed');
                        console.error('Error:', error);
                    });
            });
        },
    },
};

const QuillEditor = ({ value, setValue }) => {
    Quill.register('modules/imageUploader', ImageUploader, true);

    return (
        <ReactQuill
            theme="snow"
            value={value}
            onChange={setValue}
            style={{ marginBottom: '20px' }}
            modules={quillModules}
        />
    );
};

export default QuillEditor;
