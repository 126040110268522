import './Legal.scss';

const Avis = () => {
    return (
        <div className="legal__page__container">
            <h1>Avís legal</h1>

            <div className="point">
                <h2>1. PROPIETARI DE LA WEB:</h2>
                <p>
                    L’informem que aquesta pàgina web localitzada a l’URL: https://scv.cat/ (en
                    endavant, la “WEB”) és propietat de CATALITZA OÜ, amb número fiscal EE102192861
                    i amb domicili a Sepapaja tn 6, (15551), Tallinn, Estònia (en endavant,
                    “CATALITZA”).
                </p>
            </div>

            <div className="point">
                <h2>2. COMUNICACIONS: </h2>
                <p>
                    Qualsevol comunicació, consulta o reclamació a CATALITZA pot fer-se pels
                    següents canals:
                    <br />
                    <br />
                    Correu ordinari: Sepapaja tn 6, (15551) Tallinn, Estònia Telèfon: (+37) 26027562
                    Correu electrònic: catalitza@protonmail.com Formulari WEB: apartat “CONTACTE”
                </p>
            </div>

            <div className="point">
                <h2>3. RECOMANACIONS PRÈVIES:</h2>
                <p>
                    CATALITZA recomana la lectura exhaustiva del present AVÍS LEGAL, així com la
                    POLÍTICA DE PRIVACITAT i la POLÍTICA DE COOKIES, abans d’utilitzar la WEB i
                    accedir als continguts i serveis oferts a la WEB.
                </p>
            </div>

            <div className="point">
                <h2>4. OBJECTE DE LA WEB. SERVEIS:</h2>
                <p> La WEB ha sigut creada per a:</p>
                <ul>
                    <li>
                        Donar a conèixer els serveis oferts per CATALITZA a empreses i empresaris i
                        potencials clients (en endavant l’USUARI).
                    </li>
                    <li>
                        Permetre a l’USUARI rebre la informació sobre els serveis de CATALITZA que
                        hagi sol·licitat a través de la WEB.
                    </li>
                    <li>
                        Permetre l’USUARI contactar amb CATALITZA per tal de contractar els serveis
                        oferts a la WEB.{' '}
                    </li>
                </ul>
            </div>

            <div className="point">
                <h2>5. CONTINGUT I ACTUALITZACIÓ:</h2>
                <p>
                    La informació continguda a la WEB es la vigent a la data de la seva darrera
                    actualització (indicada a l’inici) i deu ser considerada com a informació
                    introductòria per a l’USUARI relativa als serveis i altres informacions
                    contingudes a la WEB. Si l’USUARI vol confirmar la data d’actualització de
                    qualsevol informació, pot sol·licitar-ho enviant un correu electrònic a
                    catalitza@protonmail.com.
                    <br />
                    <br />
                    El present AVÍS LEGAL té com finalitat donar a conèixer a l’USUARI de la WEB les
                    condicions que regulen l’accés i ús general de la WEB, per la qual cosa l’accés
                    i la utilització d’aquests implica necessàriament l’acceptació i compliment del
                    present AVÍS LEGAL.
                </p>
            </div>

            <div className="point">
                <h2>6. ACCÉS I UTILITZACIÓ DE LA WEB:</h2>
                <p>
                    : L’accés i la utilització de la WEB són gratuïts i es fan sota l’exclusiva
                    responsabilitat de l’USUARI, que haurà de posseir la capacitat legal i
                    representació suficients per utilitzar-la. CATALITZA rebutja qualsevol
                    responsabilitat derivada d’una utilització incorrecta dels continguts i serveis
                    exposats i oferts a la WEB.
                    <br />
                    <br />
                    L’accés i utilització de la WEB està prohibida a menors de divuit (18) anys.
                    CATALITZA pot contactar l’USUARI per tal de comprovar que compleix amb l’edat
                    mínima exigida en aquest AVÍS LEGAL.
                    <br />
                    <br />
                    L’USUARI es compromet a no utilitzar la informació i els serveis que CATALITZA
                    posa a la seva disposició per desenvolupar activitats contràries a les lleis, a
                    la moral o a l’ordre públic i es compromet a fer un ús d’acord amb les regles i
                    condicions establertes per CATALITZA. L’accés i la utilització de la WEB implica
                    el compromís i obligació de l’USUARI de fer un ús correcte de la WEB, atenint-se
                    als principis de bona fe. L’USUARI es compromet a fer un ús correcte, diligent i
                    lícit de la WEB i a no fer-ne un ús fraudulent ni portar a terme cap conducta
                    que pugui menyscabar la imatge, interessos i els drets de CATALITZA o de
                    tercers.
                    <br />
                    <br />
                    Tanmateix, l’USUARI es compromet a no realitzar cap acte amb l’objecte de
                    danyar, inutilitzar o sobrecarregar la WEB o impedir, de qualsevol manera, la
                    seva utilització i funcionament normals.
                </p>
            </div>

            <div className="point">
                <h2>7. RESPONSABILITAT PER UTILITZACIÓ DE LA WEB: </h2>
                <p>
                    L’USUARI assumeix la responsabilitat per l’ús que faci de la WEB. Aquesta
                    responsabilitat s’estén al registre que sigui necessari per accedir a
                    determinats continguts o serveis.
                    <br />
                    <br />
                    L’USUARI és l´únic responsable de la veracitat i correcció de les dades que
                    faciliti a través de la WEB, mitjançant el formulari de contacte, per la qual
                    cosa garanteix i, en tot cas, i assumeix la responsabilitat de l’exactitud,
                    vigència i autenticitat de les dades personal facilitades. L’USUARI haurà de
                    mantenir actualitzades totes les dades facilitades a través de la WEB.
                    <br />
                    <br />
                    L’USUARI es compromet a fer un ús adequat dels continguts que CATALITZA pugui
                    oferir a través de la WEB (com per exemple documents per descarregar, vídeos,
                    blog, comentaris, etc.) i, amb caràcter enunciatiu, però no limitatiu, a no
                    emprar-los per a (i) incorre en activitats il·lícites o contràries a la bona fe
                    i a l’ordre públic; (ii) difondre continguts o publicitat de caràcter
                    difamatori, racista, sexista, xenòfob, violent, pornogràfic o que resulti
                    clarament il·lícit o il·legal, d’apologia del terrorisme o atemptatori contra
                    els drets humans; (iii) provocar danys en els sistemes físics i lògics de
                    CATALITZA, dels seus proveïdors o terceres persones, introduir, difondre virus
                    informàtics o qualsevol altre sistema físic o lògic que sigui susceptible de
                    provocar els danys anteriorment mencionats.
                    <br />
                    <br />
                    L’accés i utilització de la WEB es realitza sota l’exclusiva responsabilitat de
                    l’USUARI, que haurà de posseir la capacitat legal i representació suficients per
                    a utilitzar-la.
                    <br />
                    <br />
                    Tanmateix, CATALITZA podrà bloquejar, cancel·lar o eliminar qualsevol informació
                    enviada a través de la WEB si detecta dades falses o incorrectes, o utilització
                    per a finalitats contràries a les previstes en el present AVÍS LEGAL i altres
                    condicions o directrius d’ús que estableixi CATALITZA. En qualsevol cas,
                    CATALITZA no serà responsable de les opinions efectuades per l’USUARI a través
                    de blogs, xats o altres eines de participació que, si escau, hagi habilitat
                    CATALITZA.
                </p>
            </div>

            <div className="point">
                <h2>8. MODIFICACIÓ I ACTUALITZACIÓ:</h2>
                <p>
                    CATALITZA es reserva, sense previ avís i en qualsevol moment, el dret a
                    modificar i actualitzar els continguts, els serveis, la configuració i la
                    presentació de la WEB, així com a eliminar-los i a limitat o impedir l’accés de
                    manera temporal o definitiva, ja sigui per a executar tasques de manteniment
                    rutinari del sistema, per motius de sobrecàrrega o per risc d’interrupció del
                    servei per garantir la seguretat de l’USUARI.
                    <br />
                    <br />
                    En conseqüència, en cap cas CATALITZA es responsabilitza de les pèrdues o danys
                    causats directament o indirectament com a resultat d’eventuals interrupcions o
                    suspensions que es puguin produir en la WEB.
                </p>
            </div>

            <div className="point">
                <h2>9. EXCLUSIONS DE RESPONSABILITAT:</h2>
                <p>
                    CATALITZA rebutja la responsabilitat sobre qualsevol informació no continguda a
                    la WEB i, per tant, no elaborada per CATALITZA o no publicada en nom d’aquesta.
                    De forma específica, rebutja la responsabilitat sobre la informació continguda
                    en pàgines webs o blogs de terceres persones que puguin estar connectades per
                    enllaços (links) amb la WEB de CATALITZA. <br />
                    <br />
                    CATALITZA no es fa responsable dels possibles errors de seguretat que es puguin
                    produir pel fet d’utilitzar terminals, ordinadors o dispositius infectats amb
                    virus informàtics o de les conseqüències que es puguin derivar del mal
                    funcionament del navegador o software del dispositiu, o per l’ús de versions no
                    actualitzades d’aquest. Per tant, CATALITZA no es fa responsable, en cap cas,
                    dels danys i perjudicis de qualsevol naturalesa que es puguin ocasionar a
                    l’USUARI, malgrat haver adoptat CATALITZA les mesures adequades i necessàries
                    per evitar-ho. <br />
                    <br />
                    CATALITZA no es responsabilitza dels danys i perjudicis de qualsevol classe
                    produïts per la incorrecta utilització de la WEB que en faci el mateix USUARI.
                    Tampoc es responsabilitza dels danys i perjudicis de qualsevol classe produïts a
                    l’USUARI i que portin causa d’errors, mal funcionament o desconnexions de les
                    xarxes de telecomunicacions que produeixin la suspensió, cancel·lació o
                    interrupció del servei de la WEB durant o abans del seu accés i utilització.{' '}
                </p>
            </div>

            <div className="point">
                <h2>10. PROPIETAT INDUSTRIAL I INTEL·LECTUAL: </h2>
                <p>
                    Tots els continguts de la WEB són propietat intel·lectual i industrial de
                    CATALITZA o, si s’escau, de tercers que participin de la creació de continguts o
                    serveis oferts a la WEB i que han acceptat la seva publicació a la WEB. L’USUARI
                    no tindrà cap dret de propietat intel·lectual o industrial sobre els continguts
                    de la WEB, excepte que s’estableixi el contrari de manera contractual entre
                    CATALITZA i l’USUARI. A títol merament enunciatiu, s’inclouen entre els
                    continguts els textos, fotografies, gràfics, imatges, icones, tecnologia,
                    software, links i d’altres continguts audiovisuals o sonors, així com el seu
                    disseny gràfic i codis font i binari.
                    <br />
                    <br />
                    De la mateixa manera, les marques, noms comercials o signes distintius són
                    titularitat exclusiva de CATALITZA o, si escau, de tercers que participen en la
                    creació dels continguts oferts, excepte que s’especifiqui el contrari. L’USUARI
                    haurà de respectar en tot moment tots els drets de propietat intel·lectual i
                    industrial sobre la WEB titularitat de CATALITZA o sobre continguts de tercers
                    desenvolupats i cedits a CATALITZA. En conseqüència, l’USUARI té expressament
                    prohibida la cessió de drets de propietat intel·lectual o industrial sobre la
                    WEB o els seus continguts, sense l’autorització prèvia i per escrit de CATALITZA
                    o del seu titular.
                    <br />
                    <br />
                    En cas de dubte sobre la propietat industrial o intel·lectual de qualsevol dels
                    continguts i serveis que s’ofereixen mitjançant la WEB, li recomanem que
                    contacti amb CATALITZA.
                </p>
            </div>

            <div className="point">
                <h2>11. PROHIBICIONS D’ÚS:</h2>
                <p>
                    La reproducció, còpia, ús, distribució, comercialització, comunicació pública o
                    qualsevol altra activitat que es pugui fer amb la informació, documents o
                    serveis als quals l’USUARI accedeix a través de la WEB, i que es faci sense
                    l’autorització de CATALITZA està totalment prohibida i és una infracció de la
                    legislació vigent.
                </p>
            </div>

            <div className="point">
                <h2>12. MODIFICACIONS DE L’AVÍS LEGAL I CONDICIONS D’US:</h2>
                <p>
                    En qualsevol moment, CATALITZA podrà substituir o modificar aquest AVÍS LEGAL
                    per motius tècnics o per canvis en la prestació de serveis oferts. <br />
                    <br />
                    CATALITZA li recomana a l’USUARI que consulti periòdicament l’AVÍS LEGAL, ja que
                    es poden realitzar modificacions. L’USUARI pot consultar la versió vigent de
                    l’AVÍS LEGAL en l’apartat “Avís legal” al peu de la pàgina d’inici de la WEB.
                </p>
            </div>

            <div className="point">
                <h2>13. LEGISLACIÓ APLICABLE I JURISDICCIÓ COMPETENT:</h2>
                <p>
                    La WEB i aquest AVÍS LEGAL se sotmeten a la legislació belga. Per a qualsevol
                    controvèrsia entre l’USUARI i CATALITZA, vinculada a la WEB i a la seva
                    utilització, les parts es sotmeten expressament als jutjats i tribunals en
                    Holandès de la ciutat de Gent, Bèlgica.
                </p>
            </div>
        </div>
    );
};

export default Avis;
