import HeaderBanner from '../../../shared/components/HeaderBanner';
import CustomLottie from '../../../shared/components/Lottie';
import Example from './components/Example';

import Hacker from '../../../../assets/icons/hack.png';
import Cash from '../../../../assets/icons/cash.png';
import Private from '../../../../assets/icons/document.png';
import Fake from '../../../../assets/icons/document2.png';
import Drugs from '../../../../assets/icons/drugs.png';
import Ghost from '../../../../assets/icons/ghost.png';
import Criminal from '../../../../assets/icons/criminal.png';
import Bullying from '../../../../assets/icons/bullying.png';

import Lupa from '../../../../assets/lotties/lupa.json';
import Deal from '../../../../assets/lotties/deal.json';

import Arrow from '../../../shared/components/Arrow';

import AnchorLink from 'react-anchor-link-smooth-scroll';

import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import './EyeDetectPage.scss';

const EyeDetectPage = () => {
    const { t } = useTranslation();

    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

    return (
        <div className="eyedetect__main__container">
            <HeaderBanner
                title={t('Eyedetect')}
                subtitle={t('Trobem ràpidament la veritat amb la tecnologia més avançada')}
                lottie={Lupa}
                anchor="eyedetect"
            />

            <div className="page__container" id="eyedetect">
                {/* <FollowEyes /> */}
                <div className="first__row">
                    <CustomLottie lottie={Deal} size={isMobile ? 300 : 400} />
                    <div className="info__container">
                        <div className="section__title__container">
                            <h2>{t('Confia en les persones')}</h2>
                            <h3>{t('Sistema de validació no intrusiu, automàtic i precís')}</h3>
                        </div>
                        <div className="text__container">
                            <p>
                                {t(
                                    "Els testos de SCV permeten garantir que les persones responen al nivell d'integritat i confiança que el teu projecte, organització o entorn de privacitat necessita."
                                )}
                            </p>
                            <AnchorLink href="#contacta">
                                <div className="contact__button__interior">
                                    <button>{t('Solicita una demostració')}</button>
                                </div>
                            </AnchorLink>
                        </div>
                    </div>
                </div>

                {/* <TextAnimated wordOne="EyeDetect" wordTwo="The eyes don't lie" /> */}

                <div className="second__row">
                    <div className="text__container">
                        <p>
                            <span style={{ color: '#fe507c' }}>EyeDetect®</span>{' '}
                            {t(
                                'és un innovador sistema automatitzat de detecció de mentides basat en fluctuacions en les pupil·les dels ulls.'
                            )}
                            <br />
                            <br />
                            {t(
                                'La tecnologia es basa en mesurar la càrrega cognitiva mentre es respon un test específcament dissenyat per SCV segons les necessitats del client.'
                            )}
                            <br />
                            <br />
                            {t(
                                'El resultat s’obté en qüestió de minuts en un entorn on la privacitat està 100% garantida.'
                            )}
                        </p>
                    </div>

                    <div className="examples__container">
                        <Example icon={Bullying} title={t('Assetjament')} />
                        <Example icon={Criminal} title={t('Robatoris')} />
                        <Example icon={Private} title={t('Informació confidencial')} />
                        <Example icon={Drugs} title={t('Drogues')} />
                        <Example icon={Fake} title={t('Frau documental')} />
                        <Example icon={Cash} title={t('Suborns')} />
                        <Example icon={Ghost} title={t('Enemics')} />
                        <Example icon={Hacker} title={t('Cibercrims')} />
                    </div>
                </div>
                <AnchorLink href="#contacta">
                    <Arrow />
                </AnchorLink>
            </div>
        </div>
    );
};

export default EyeDetectPage;
