// React utils
import { useEffect, useState, Fragment } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link, useNavigate } from 'react-router-dom';
import { useMyContext } from 'contexts/AppContext';
import { useMediaQuery } from 'react-responsive';
import { Zoom } from 'react-awesome-reveal';

// Components
import Menu from './Menu';
import MobileMenu from './MobileMenu';
import LanguageSwitcher from 'modules/shared/components/LangSwitcher';

// Icons
import MenuIcon from '@mui/icons-material/Menu';

// Images
import Logo from 'assets/images/logo.png';
import LogoExtraLarge from 'assets/images/logo-extra-large.png';

// i18n
import { useTranslation } from 'react-i18next';

// Scss
import './Header.scss';

const Header = () => {
    const { t } = useTranslation();
    const { isLogged } = useMyContext();
    const navigate = useNavigate();
    const [transNav, setTransNav] = useState(true);
    const { toogleFormFooter } = useMyContext();

    const [toogleMblMenu, setToogleMblMenu] = useState(false);

    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

    const openMenu = () => {
        setToogleMblMenu(true);
    };

    const closeMenu = () => {
        setToogleMblMenu(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', () => {
            colorNavOnScroll();
        });
    }, [transNav]);

    useEffect(() => {
        closeMenu();
    }, [navigate]);

    const colorNavOnScroll = () => {
        if (window.scrollY === 0) setTransNav(true);
        if (window.scrollY > 0 && transNav) setTransNav(false);
    };

    return (
        <div
            className={
                transNav ? 'header__container header__inactive' : 'header__container header__active'
            }
        >
            {isMobile ? (
                <Link to={'/'}>
                    <div className="logo__container">
                        <img src={Logo} className="logo__small show" alt="" />
                    </div>
                </Link>
            ) : (
                <div className="header__container--elements">
                    <div className="logo__container">
                        <Link to={'/'}>
                            <img
                                src={Logo}
                                className={transNav ? 'logo__small show' : 'logo__small hide'}
                                alt=""
                            />
                        </Link>
                        <Link to={'/'}>
                            <img
                                src={LogoExtraLarge}
                                className={transNav ? 'logo__large hide' : 'logo__large show'}
                                alt=""
                            />
                        </Link>
                    </div>
                </div>
            )}

            {isMobile ? (
                <Fragment>
                    <div className="mobile__container">
                        <MenuIcon className="menu__icon" onClick={openMenu} />
                        {toogleMblMenu && (
                            <div
                                className={
                                    toogleMblMenu
                                        ? 'mobile__menu__active__container'
                                        : 'mobile__menu__inactive__container'
                                }
                            >
                                <MobileMenu closeMenu={closeMenu}>
                                    <Menu />
                                    <div className="button__menu__container">
                                        <Zoom delay={1000}>
                                            <AnchorLink href="#contacta">
                                                <button
                                                    onClick={() => {
                                                        closeMenu();
                                                        toogleFormFooter(false);
                                                    }}
                                                >
                                                    {t('CONTACTA')}
                                                </button>
                                            </AnchorLink>
                                        </Zoom>
                                    </div>
                                </MobileMenu>
                            </div>
                        )}
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    <Menu />
                    <div className="button__menu__container">
                        <div className="button__menu--actions">
                            {isLogged && (
                                <Link to={'/badulake'}>
                                    <button>ADMIN</button>
                                </Link>
                            )}
                            <AnchorLink href="#contacta">
                                <button onClick={() => toogleFormFooter(false)}>
                                    {t('CONTACTA')}
                                </button>
                            </AnchorLink>
                        </div>

                        <div className="langswitcher__container">
                            <LanguageSwitcher />
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
};

export default Header;
