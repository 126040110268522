import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import './DropSelect.scss';

const DropSelect = ({ handleCategories, selectedCategory, categories }) => {
    return (
        <FormControl fullWidth className="dropselect__container">
            <InputLabel className="label__drop">Categories</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                className="select__drop"
                value={selectedCategory || ''}
                label={selectedCategory}
                onChange={handleCategories}
                autoWidth
            >
                {categories &&
                    categories.map((e, key) => (
                        <MenuItem divider value={e} key={key} className="item__drop">
                            {e.toUpperCase()}
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
};

export default DropSelect;
