import { useMyContext } from 'contexts/AppContext';
import { Fragment } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
    const { isLogged } = useMyContext();
    const location = useLocation();

    return isLogged ? (
        children
    ) : (
        <Navigate to={{ pathname: '/emart', state: { from: location } }} />
    );
};

export default PrivateRoute;
