// React utils
import * as React from 'react';

// MUI
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

// Components
import PostList from './PostList';
import PostForm from './PostForm';

const Menu = () => {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Posts" value="1" />
                        <Tab label="+ afegir post" value="2" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <PostList />
                </TabPanel>
                <TabPanel value="2">
                    <PostForm />
                </TabPanel>
            </TabContext>
        </Box>
    );
};

export default Menu;
