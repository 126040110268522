// React utils
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Zoom } from 'react-awesome-reveal';

// Components
import FireLogo from '../../../../../shared/components/FireLogo';
import Arrow from '../../../../../shared/components/Arrow';

// i18n
import { useTranslation } from 'react-i18next';

// Scss
import './Superhero.scss';

const Superhero = () => {
    const { t } = useTranslation();

    return (
        <div className="superhero__container">
            <Zoom duration={2000} triggerOnce>
                <FireLogo />
            </Zoom>
            <div className="text__container">
                <h1>{t('Serveis de ContraVigilància')}</h1>
                <h2>{t('Tecnologia i experiència al servei de la seguretat i la privacitat')}</h2>
            </div>

            <AnchorLink href="#informacio">
                <Arrow />
            </AnchorLink>
        </div>
    );
};

export default Superhero;
