import Menu from './components/Menu';

const BlogCMS = () => {
    return (
        <div className="blogcms__container">
            <h1>Gestiona el blog</h1>
            <Menu />
        </div>
    );
};

export default BlogCMS;
