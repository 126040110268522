import './Example.scss';

const Example = ({ icon, title }) => {
    return (
        <div className="single__example">
            <img src={icon} alt="" />
            <h3>{title}</h3>
        </div>
    );
};

export default Example;
