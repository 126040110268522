import './FireLogo.scss';

const FireLogo = () => {
    return (
        <div className="firelogo__container">
            <h1>
                <span className="fire">S</span>
                <span className="burn">C</span>
                <span className="fire">V</span>
            </h1>
        </div>
    );
};

export default FireLogo;
