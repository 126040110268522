// React Utils
import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

// Components
import PrivateRoute from 'routes/PrivateRoute';

// Contexts
import { AppContextProvider } from './contexts/AppContext';

// Layouts
import Layout from './layout/Layout';

// Pages
import Home from './modules/siteweb/pages/Home/Home';
import InspeccionsPage from './modules/siteweb/pages/Inspeccions/InspeccionsPage';
import ComunicacionsPage from './modules/siteweb/pages/Comunicacions/ComunicacionsPage';
import EyeDetectPage from './modules/siteweb/pages/EyeDetect/EyeDetectPage';

import BlogPage from './modules/siteweb/pages/Blog/BlogPage';
import SingleEntry from './modules/siteweb/pages/Blog/SingleEntry';

import Privacy from './modules/siteweb/pages/Legal/Privacy';
import Avis from './modules/siteweb/pages/Legal/Avis';
import Cookies from './modules/siteweb/pages/Legal/Cookies';

import Login from 'modules/admin/pages/Login/Login';
import Dashboard from './modules/admin/pages/Dashboard/Dashboard';
import BlogCMS from './modules/admin/pages/BlogCMS/BlogCMS';
import ContactCMS from 'modules/admin/pages/ContactCMS/ContactCMS';
import UsersCMS from 'modules/admin/pages/UsersCMS/UsersCMS';
import EntryEditor from 'modules/admin/pages/BlogCMS/components/FormEntry';

// Hooks
import UseLoadPosts from 'modules/siteweb/hooks/UseLoadPosts';

// Services
import { logout } from 'api/services/auth';

// Scss
import './App.scss';

function App({ logged }) {
    const navigate = useNavigate();
    const [isLogged, setIsLogged] = useState(logged);
    const [formSended, setFormSended] = useState(false);

    const toogleFormFooter = (action) => {
        setFormSended(action);
    };

    const userLogin = () => {
        setIsLogged(true);
    };

    const userLogout = () => {
        logout().then(() => setIsLogged(false));
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);

    const posts = UseLoadPosts();

    return (
        <AppContextProvider
            value={{ formSended, toogleFormFooter, posts, isLogged, userLogout, userLogin }}
        >
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/inspeccions" element={<InspeccionsPage />} />
                    <Route path="/comunicacions" element={<ComunicacionsPage />} />
                    <Route path="/eyedetect" element={<EyeDetectPage />} />
                    {/* blog */}
                    <Route path="/blog" element={<BlogPage />} />
                    <Route path="/blog/:id" element={<SingleEntry />} />
                    {/* legal pages */}
                    <Route path="/politica-de-privacitat" element={<Privacy />} />
                    <Route path="/politica-de-galetes" element={<Cookies />} />
                    <Route path="/avis-legal" element={<Avis />} />
                    {/* cms */}
                    <Route
                        path="/badulake"
                        element={
                            <PrivateRoute>
                                <Dashboard />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/badulake/users"
                        element={
                            <PrivateRoute>
                                <UsersCMS />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/badulake/messages"
                        element={
                            <PrivateRoute>
                                <ContactCMS />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/badulake/blog"
                        element={
                            <PrivateRoute>
                                <BlogCMS />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/badulake/blog/editor/:id"
                        element={
                            <PrivateRoute>
                                <EntryEditor />
                            </PrivateRoute>
                        }
                    />
                    {/* login page */}
                    <Route path="/emart" element={<Login />} />{' '}
                </Routes>
            </Layout>
        </AppContextProvider>
    );
}

export default App;
