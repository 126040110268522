import HeaderBanner from '../../../shared/components/HeaderBanner';

import Eye from '../../../../assets/lotties/eye.json';

import AmenacesPhoto from '../../../../assets/images/amenaces.png';
import EscombratPhoto from '../../../../assets/images/escombrat.png';

import { useMediaQuery } from 'react-responsive';
import Arrow from '../../../shared/components/Arrow';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { useTranslation } from 'react-i18next';

import './InspeccionsPage.scss';

const InspeccionsPage = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
    const { t } = useTranslation();

    return (
        <div className="inspeccions__main__container">
            <HeaderBanner
                title={t('Inspeccions')}
                subtitle={t('Detectem amenaces físiques contra la teva privacitat')}
                lottie={Eye}
                anchor="inspeccions"
            />

            <div className="page__container" id="inspeccions">
                <div className="first__row">
                    <div className="image__container">
                        <div className={!isMobile && 'image'}>
                            <img src={AmenacesPhoto} className="img" alt="" id="first-image" />
                        </div>
                    </div>
                    <div className="text__container">
                        <h3>{t('Micròfons i dispositius de seguiment')}</h3>
                        <h4>{t('Amenaces')}</h4>
                        <h5>{t('L’espionatge disposa d’avançada tecnologia')}</h5>
                        <p>
                            {t(
                                "Avui en dia existeixen micròfons més petits que una moneda de d'un cèntim que poden amagar-se en els connectors de xarxa o tecnologies que poden escoltar converses mirant la llum de les sales. SCV disposa dels coneixements i tecnologies per detectar amenaces i establir protocols per garantir espais i vehicles sense amenaces."
                            )}
                        </p>
                        <div className="contact__button__interior">
                            <AnchorLink href="#contacta">
                                <button>{t("Contacta'ns")}</button>
                            </AnchorLink>
                        </div>
                    </div>
                </div>

                <div className="second__row">
                    <div className="image__container">
                        <div className={!isMobile && 'image'}>
                            <img
                                src={EscombratPhoto}
                                className="photo__3d"
                                alt=""
                                id="second-image"
                            />
                        </div>
                    </div>
                    <div className="text__container">
                        <h3>{t('Espectòmetres i detectors de dispositius')}</h3>
                        <h4>{t("Servei d'inspecció")}</h4>
                        <h5>{t('A la cerca de senyals i elements amenaçadors')}</h5>
                        <p>
                            {t("El nostre servei d'escombrat inclou:")}
                            <br /> <br />
                            {t('Anàlisi espectral de feqüències')} <br />
                            {t('Anàlisi de corrents portadores')}
                            <br />
                            {t('Detecció d’unions no lineals')}
                            <br />
                            {t('Escanejat termogràfic')}
                            <br />
                            {t('Inspecció física')}
                        </p>
                        <div className="contact__button__interior">
                            <AnchorLink href="#contacta">
                                <button>{t("Contacta'ns")}</button>
                            </AnchorLink>
                        </div>
                    </div>
                </div>
            </div>
            <AnchorLink href="#contacta">
                <Arrow />
            </AnchorLink>
        </div>
    );
};

export default InspeccionsPage;
