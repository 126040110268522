// React utils
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useMyContext } from 'contexts/AppContext';
import { useTranslation } from 'react-i18next';

// Components
import HeaderBanner from '../../../shared/components/HeaderBanner';
import BlogLottie from '../../../../assets/lotties/blog.json';
import CardBlog from './components/CardBlog';
import DropSelect from '../../../shared/components/DropSelect';

// Services
import { getCategories } from 'api/services/posts';

// SCSS
import './BlogPage.scss';

const BlogPage = () => {
    const { t } = useTranslation();
    const { posts } = useMyContext();
    const [categories, setCategories] = useState(null);
    const [categorySelected, setSelectedCategory] = useState(null);
    const [postsFiltered, setPostsFiltered] = useState(posts);

    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

    const resetPostFiltered = () => {
        setPostsFiltered(posts);
        setSelectedCategory(null);
    };

    const handleCategories = (event) => {
        const category = event.target.value || event.target.innerText.toLowerCase();
        setSelectedCategory(category);

        const filter = posts.filter((e) => e.categories.includes(category));

        setPostsFiltered(filter);
    };

    useEffect(() => {
        const loadCategories = () => {
            getCategories()
                .then((response) => {
                    setCategories(response);
                })
                .catch((error) => console.log(error));
        };

        if (posts) {
            setPostsFiltered(posts);
        }

        loadCategories();
    }, [posts]);

    return (
        <div className="blogpage__main__container">
            <HeaderBanner
                title={t('Blog')}
                subtitle={t('Ràfegues de privacitat i contravigilància')}
                lottie={BlogLottie}
                anchor="blog"
            />

            <div className="page__content" id="blog">
                <aside>
                    <div className="categories__container">
                        <button
                            onClick={resetPostFiltered}
                            style={{ color: !categorySelected && 'red' }}
                        >
                            {t('VEURE TOTES')}
                        </button>
                        {isMobile ? (
                            <DropSelect
                                handleCategories={handleCategories}
                                selectedCategory={categorySelected}
                                categories={categories}
                            />
                        ) : (
                            categories &&
                            categories.map((e, key) => (
                                <button
                                    key={key}
                                    onClick={handleCategories}
                                    style={{ color: categorySelected === e && 'red' }}
                                >
                                    {e.toUpperCase()}
                                </button>
                            ))
                        )}
                    </div>
                </aside>
                <div className="posts__grid__container">
                    {postsFiltered &&
                        postsFiltered.map((e, key) => <CardBlog content={e} key={key} />)}
                </div>
            </div>
        </div>
    );
};

export default BlogPage;
