// React utils
import { Link } from 'react-router-dom';

// Icons
import NotesIcon from '@mui/icons-material/Notes';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';

// Components
import Card from './components/Card';

// SCSS
import './Dashboard.scss';

const Dashboard = () => {
    return (
        <div className="dashboard__container">
            <h1>Dashboard</h1>
            <div className="features__container">
                <div className="feature">
                    <Link to="blog">
                        <Card name="blog">
                            <NotesIcon className="card__icon" />
                        </Card>
                    </Link>
                </div>

                <div className="feature">
                    <Link to="messages">
                        <Card name="missatges">
                            <EmailIcon className="card__icon" />
                        </Card>
                    </Link>
                </div>

                <div className="feature">
                    <Link to="users">
                        <Card name="usuaris">
                            <PersonIcon className="card__icon" />
                        </Card>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
