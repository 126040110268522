// React utils
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Icons
import BorderColorIcon from '@mui/icons-material/BorderColor';

// Services
import { getPosts } from 'api/services/posts';

// Utilñ
import { isoToShort } from 'utils/time';

// SCSS
import './PostList.scss';

const PostList = () => {
    const [posts, setPosts] = useState(null);

    useEffect(() => {
        getPosts()
            .then((data) => setPosts(data))
            .catch((error) => console.log(error));
    }, []);

    return (
        <div className="postlist__container">
            {posts && posts.length > 0 ? (
                posts.map((e) => (
                    <div className="postlist__item" key={e.id}>
                        <Link to={`editor/${e.id}`}>
                            <div className="postlist__item__header">
                                <BorderColorIcon />
                                <h2 className="postlist__title">{e.title}</h2>
                            </div>
                            <h2 className="postlist__date">{isoToShort(e.createdAt)}</h2>
                            <h2 className="postlist__categories">{e.categories.toString()}</h2>
                        </Link>
                    </div>
                ))
            ) : (
                <h2>No hi ha posts</h2>
            )}
        </div>
    );
};

export default PostList;
