// React utils
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

// Components
import SocialShare from '../../../shared/components/SocialShare';
import CardBlog from './components/CardBlog';

// Utils
import { useMyContext } from 'contexts/AppContext';
import { isoToShort } from 'utils/time';

// Services
import { getSinglePost } from 'api/services/posts';

// i18n
import { useTranslation } from 'react-i18next';

// Scss
import './SingleEntry.scss';

const SingleEntry = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [entry, setEntry] = useState(null);

    const { posts } = useMyContext();

    useEffect(() => {
        const loadEntry = () => {
            getSinglePost(id)
                .then((response) => setEntry(response))
                .catch((error) => console.log(error));
        };

        loadEntry();
    }, [id]);

    console.log(entry);

    return (
        <>
            {entry && (
                <div className="singleentry__main__container">
                    <div className="singleentry_title__and__banner">
                        <div className="taxonomies__container">
                            <h3>{isoToShort(entry.createdAt)}</h3>
                        </div>
                        <div className="title__container">
                            <h1>{entry.title}</h1>
                        </div>
                        <div className="image__related__container">
                            <img src={entry.image} alt="" />
                        </div>
                    </div>

                    <div
                        className="singleentry__content"
                        dangerouslySetInnerHTML={{ __html: entry.content }}
                    ></div>

                    <div className="socialshare">
                        <SocialShare
                            text={entry.title}
                            url={window.location.href}
                            title={entry.title}
                        />
                    </div>

                    <div className="related__posts__container">
                        <h2 className="related__title">{t('Entrades relacionades')}</h2>
                        <div className="related__cards">
                            {posts &&
                                posts
                                    .filter((e) => e.id !== id)
                                    .map((e, key) => <CardBlog content={e} key={key} />)}
                        </div>

                        <div className="forward__buttons">
                            <div className="contact__button__interior all__entries">
                                <Link to={'/blog'}>
                                    <button>{t('Veure totes les entrades')}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SingleEntry;
