// React utils
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// Regex
import { isEmail } from 'utils/regex';

// Components
import InputField from '../BlogCMS/components/InputField';
import { Checkbox, FormControlLabel } from '@mui/material';

// Services
import { login } from 'api/services/auth';

// Context
import { useMyContext } from 'contexts/AppContext';

// Images
import kwik from 'assets/images/kwik.png';

// SCSS
import './Login.scss';

const Login = () => {
    const navigate = useNavigate();
    const { userLogin } = useMyContext();

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        login(data).then(({ status }) => {
            if (status === 402) {
                setError('login', {
                    type: 'manual',
                    message: 'Oooops! Prova-ho de nou.',
                });
            }

            if (status === 200) {
                userLogin();
                navigate('/badulake');
            }
        });
    };

    return (
        <div className="loginpage__container">
            <img src={kwik} alt="" width={150} style={{ marginLeft: '-10px' }} />

            <div className="reacthookform__container">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputField
                        name="email"
                        placeholder="Correu electrònic"
                        autoComplete="username"
                        errors={errors.email || null}
                        {...register('email', {
                            pattern: {
                                value: isEmail,
                                message: 'No és correcte, my friend',
                            },
                            required: "S'ha d'omplir aquest camp",
                        })}
                    />

                    <InputField
                        type="password"
                        name="password"
                        placeholder="Password"
                        errors={errors.password || null}
                        autoComplete="current-password"
                        {...register('password', {
                            required: "S'ha d'omplir aquest camp",
                        })}
                    />
                    <div className="remember__container">
                        <FormControlLabel
                            control={<Checkbox defaultChecked {...register('remember')} />}
                            label="Vols guardar la teva sessió?"
                        />
                    </div>

                    <p style={{ color: 'red' }}>{errors.login?.message}</p>

                    <button>Entrar</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
