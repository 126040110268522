import './Legal.scss';

const Privacy = () => {
    return (
        <div className="legal__page__container">
            <h1>Privacy Policy</h1>
            <div className="point">
                <p>
                    Valid from 22.10.2020
                    <br />
                    <br />
                    This Privacy Policy describes the general principles on how CATALITZA OÜ
                    (registry code 14793228 ) and CATALITZA CSR MTÜ (registry code 80584423) address
                    Keemia tn 4, Tallinn, 10616, Estonia with the email address:
                    catalitza@protonmail.com (hereinafter “CATALITZA ”) processes Users’ Personal
                    Data and is applicable to any person who uses, has used or has expressed intent
                    to use the Services of CATALITZA. CATALITZA is responsible for Processing your
                    Personal Data. All of the definitions used in and all circumstances not covered
                    by this Privacy Policy shall be regulated by the Terms and & Conditions of
                    CATALITZA and/or Regulation (EU) 2016/679.
                    <br />
                    <br />
                    Please read this Privacy Policy before using the Website and/or Services or
                    submitting your Personal Data.
                </p>
            </div>

            <div className="point">
                <h2>1. Definitions</h2>
                <p>
                    Here you can find the meanings of the most important terms in this Privacy
                    Policy to help you understand who and for what CATALITZA is Processing your
                    Personal Data.
                </p>
                <ul>
                    <li>Agreement - service agreement concluded with the client.</li>
                    <li>
                        Cookie(s) - small pieces of information stored by the Visitor’s browser on
                        the hard disk of the computer of any other device of the Visitor.
                    </li>
                    <li>
                        Data Subject / you - natural person about whom we have got information or
                        data enabling to identify the natural person. Data Subjects are our client’s
                        representatives, Users and Visitors. CATALITZA does not knowingly collect or
                        maintain personally identifiable information on the Website or through its
                        Services from persons under 16 years of age, except if a guardian of such
                        child has consented to the Processing.
                    </li>
                    <li>
                        GDPR - Regulation 2016/679 of the European Parliament and of the Council of
                        27 April 2016 on the protection of natural persons with regard to the
                        Processing of Personal Data and on the free movement of such data, and
                        repealing Directive 95/46/EC (General Data Protection Regulation).
                    </li>
                    <li>
                        Personal Data - means any information which CATALITZA directly relates to
                        you (a legal or natural person or an organization legally engaged in
                        business activities or any other business). In particular by reference to an
                        identifier such as a name, date of birth, contact information, location
                        data, an online identifier etc. Processing may be done manually or using
                        automated systems.
                    </li>
                    <li>Privacy Policy - this privacy policy.</li>
                    <li>
                        Process or Processing - means any operation or set of operations which is
                        performed by CATALITZA as part of the Services on Personal Data or on sets
                        of Personal Data, whether or not by automated means.
                    </li>
                    <li>Processor and Controller - shall have the meanings given in the GDPR.</li>
                    <li>Visitor / User - is any person using the Services of CATALITZA.</li>
                    <li>
                        Website - catalitza.com, .org and all domains that CATALITZA may choose to
                        use for delivering its services.
                    </li>
                </ul>
            </div>

            <div className="point">
                <h2>2. The content of Personal Data CATALITZA Processes</h2>
                <p>
                    By agreeing to this Agreement or using the Services and the Website, you agree
                    that the CATALITZA will use, disclose and Process your Personal Data in
                    accordance with this Privacy Policy:
                </p>
                <ul>
                    <li>
                        CATALITZA Terms of Service and present Privacy Policy applies to
                        communication between you and CATALITZA (incl. emails, phone calls, etc.)
                        and to your use of the Website and Services, and establishes the policy on
                        which Personal Data will be Processed by CATALITZA.
                    </li>
                    <li>
                        CATALITZA may Process any information submitted, shared or exchanged to
                        provide the Services. CATALITZA collects information by email, phone calls
                        or by questionnaire or any other means. Some information may also be
                        collected via automatic means e.g. Website telemetry/analytics. CATALITZA
                        may also collect information from third parties e.g. verification services.
                        CATALITZA shall do all that is reasonably possible to ensure the
                        confidentiality of the Personal Data that CATALITZA has collected.
                    </li>
                    <li>
                        CATALITZA Processes Personal Data only if it has your consent to do so, or
                        if it is necessary for providing you the Services, or CATALITZA has the
                        legitimate interest to do so, or if it is required by applicable
                        legislation.
                    </li>
                    <li>
                        CATALITZA mainly Processes data to comply with applicable laws, regulations
                        and instructions, to manage the relationship between CATALITZA and you, to
                        provide additional Services, to prevent illegal activities and misuse of the
                        Service and Website, to ensure the quality of the Service and Website or
                        app, etc.
                    </li>
                    <li>
                        You might be required to provide your real name, address, nationality,
                        telephone number, e-mail address, as well as other information as required
                        by applicable laws and regulations. You can also choose to fill in
                        additional information. By providing the previously mentioned information
                        you understand and agree to that CATALITZA will access your Personal Data
                        (including but not limited to mobile phones, electronic mail, etc.) to send
                        you relevant notices and other commercial electronic information to provide
                        you with new Services and opportunities. CATALITZA will not share your
                        Personal Data without your previous consent unless legally obliged to.
                    </li>
                    <li>
                        CATALITZA collects some of the information automatically. Information that
                        is collected automatically is used with the sole purpose of improving the
                        user experience and detecting problems of the website or app. CATALITZA may
                        collect only information that does not constitute identifiable personal data
                        (incl. truncated IP address, operating system, language settings,
                        subscriptions, and other information about the interaction of you and the
                        Website). CATALITZA will not collect any information that constitutes
                        personal information (incl. Name, Last names, full IP address, Public key or
                        location). CATALITZA may use authorized Processors and Services provided by
                        third parties to collect and Process Data.
                    </li>
                    <li>
                        In order to ensure the safety of your use of the Services and the Website
                        and to continuously improve the quality of service, CATALITZA will record
                        and keep information about your login and use of the Service and the
                        Website, but CATALITZA undertakes not to provide such information to any
                        third party (unless otherwise agreed upon by both parties, or other laws and
                        regulations).
                    </li>
                    <li>
                        CATALITZA will keep tight security of all the complete comprehensive
                        statistics regarding identity data of CATALITZA Visitors and will not use
                        and/or disclose them for sale and reward. CATALITZA reserves the right to
                        transmit the Personal Data to law enforcement institutions, state
                        authorities and financial institutions if it should be required to comply
                        with valid laws.
                    </li>
                </ul>
            </div>

            <div className="point">
                <h2>3. Disclosure and transfer of Personal Data</h2>
                <p>
                    CATALITZA only Processes your Personal Data on your consent, if there is a
                    legitimate interest in Process Data or if it is required by valid legislation.
                    In order to provide you with a more efficient service, you agree that CATALITZA
                    shall have the right to provide your registered information which you have
                    furnished to CATALITZA in the course of your registration and use of the
                    Service. At the same time, CATALITZA reserves the right to require you to submit
                    identity information (including but not limited to identity card, bank account
                    book, passports, and other certificates or other documents). Except as otherwise
                    provided in Terms of Service or present Privacy Policy, CATALITZA does not
                    disclose or provide your information to third parties except in the following
                    cases:
                </p>
                <ul>
                    <li>with your explicit authorization;</li>
                    <li>
                        only to disclose your Personal Data in order to provide you with required
                        products and/or Services;
                    </li>
                    <li>disclosure in accordance with the requirements of this Privacy Policy;</li>
                    <li>according to the provisions of applicable laws and regulations;</li>
                    <li>
                        in accordance with the instruction and requirements of applicable laws and
                        Authorities etc;
                    </li>
                    <li>for the maintenance of CATALITZA legitimate rights and interests;</li>
                    <li>
                        to verify the authenticity of the identity of you or your representative
                        company.
                    </li>
                </ul>
            </div>

            <div className="point">
                <h2>4. Retention of Personal Data</h2>
                <p>
                    CATALITZA shall store your Personal Data for as long as required by law or in
                    accordance with the law, or for the purposes stated in this Privacy Policy. Your
                    Personal Data will be Processed no longer than necessary. The retention period
                    of your Personal Data may be based on the applicable law (e.g. Money Laundering
                    and Terrorist Financing Prevention Act, etc.) or legitimate interest of
                    CATALITZA or Agreements signed between you and CATALITZA.
                    <br />
                    <br />
                    The data shall be retained in personal form for the duration of the business
                    relationship and once terminated, during the term applicable by law or
                    regulations to which CATALITZA is subject to the retention of the specific data.
                    <br />
                    <br />
                    CATALITZA does not sell or rent Visitor’s data to third parties. CATALITZA
                    collects data so that you could use the Services.
                    <br />
                    <br />
                    Using forms and other means, Visitors can send data to register themselves to
                    external organizations that use the Website as a participation platform. Please
                    be aware that CATALITZA has no control over the content you share with these
                    third-parties entities, and cannot accept responsibility or liability for their
                    respective privacy policies.
                    <br />
                    <br />
                    The Personal Data collected by CATALITZA is not transmitted to countries outside
                    the European Union or to countries that lack Personal Data protection
                    legislation in compliance with the standards of the European Union. Data is
                    stored in servers located in a European Union member state.
                    <br />
                    <br />
                    You reserve the right to demand access, correction and deletion of the data
                    collected with your consent at all times.
                    <br />
                    <br />
                    After the expiration of the Personal Data storage period, CATALITZA shall
                    anonymize or permanently erase your Personal Data.
                </p>
            </div>

            <div className="point">
                <h2>Data Subject’s rights in relation to Personal Data</h2>
                <p>
                    If you wish to exercise any of your rights regarding Personal Data or ask
                    questions about the Privacy Policy, please submit a corresponding request to us
                    at catalitza@protonmail.com. We will respond to your request by email as a rule
                    no later than within one month. Please note that before we can provide you with
                    the requested information regarding your Personal Data, we need to verify your
                    identity.
                </p>
                <p>You as a Data Subject have the following rights in relation to Personal Data:</p>
                <ul>
                    <li>
                        Right of access to Personal Data - you have the right to know which of your
                        Personal Data we store and how we Process it, including the right to know
                        the purpose of the Processing, the persons to whom we will disclose your
                        Personal Data, information about automated decision-making and the right to
                        receive copies of Personal Data.
                    </li>
                    <li>
                        Right to rectification of Personal Data - you have the right to request the
                        rectification of inadequate, incomplete and misleading Personal Data.
                    </li>
                    <li>
                        Right to withdraw the consent given for the Processing of Personal Data -
                        you have the right at any time to withdraw the consent given to us for the
                        Processing of Personal Data. Please note that withdrawal of your consent
                        shall not affect the legality of the Processing that was made on the basis
                        of consent before the withdrawal.
                    </li>
                    <li>
                        Right to erasure of Personal Data („right to be forgotten“) - you have the
                        right to request that we erase your Personal Data (for example, if you take
                        back the consent for the Processing of Personal Data, or if Personal Data is
                        no longer needed for the purpose for which it was collected). We have the
                        right to refuse the erasure of Personal Data if the Processing of Personal
                        Data is necessary for the fulfilment of our legal obligation, to exercise
                        the right to freedom of expression and information, for the preparation,
                        presentation and protection of legal claims, or in the public interest.
                    </li>
                    <li>
                        Right to restriction of Processing - In certain cases, you have the right to
                        prohibit or restrict Processing of your Personal Data for a certain period
                        of time (e.g., if you have filed an objection to Personal Data Processing).
                    </li>
                    <li>
                        Right to object - you have the right to file an objection to Processing of
                        your Personal Data if your Personal Data Processing takes place on the basis
                        of our legitimate interest or public interest. You shall have the right to
                        object at any time to Processing of Personal Data for direct marketing
                        purposes, and we shall respond immediately.
                    </li>
                    <li>
                        Right to data portability - In case your Personal Data Processing is based
                        on your consent and Personal Data is processed automatically, you shall be
                        entitled to receive Personal Data about you that you submitted to us as the
                        controller, in a structured, commonly used and machine-readable format, and
                        you shall have the right to transmit this Personal Data to another
                        controller. You also have the right to request that we transfer Personal
                        Data directly to another controller, where technically feasible.
                    </li>
                    <li>
                        Automated decision-making (including profiling) - provided that we have
                        informed You that we perform automated decision-making (including profiling)
                        that will bring about legal consequences for you or have a significant
                        effect on you, then you may require human intervention in the
                        decision-making process.
                    </li>
                    <li>
                        Submission of complaint - In case you find that your rights have been
                        breached, you have the right to seek protection and file a complaint to the
                        Data Protection Inspectorate.
                    </li>
                </ul>

                <p>Please read more about your rights from chapter 3 of the GDPR.</p>
            </div>

            <div className="point">
                <h2>6. Security of Personal Data</h2>
                <p>
                    Security is of utmost importance to us. We do our best to protect Personal Data
                    in our hands.
                    <br /> <br />
                    We apply various measures (physical, technical, organizational) to protect your
                    Personal Data from unauthorized or arbitrary rectification, disclosure,
                    acquisition, destruction, loss or unauthorized access.
                    <br /> <br />
                    If you have any information about an actual or suspected data breach, please
                    inform us immediately at catalitza@protonmail.com. We will deal with the issue
                    immediately and inform the Data Protection Inspectorate (if applicable).
                </p>
            </div>

            <div className="point">
                <h2>7. Cookies</h2>
                <p>
                    Cookies are small files text files placed on your device when you visit a
                    Website. We use Cookies on our Website, that you can accept if you choose to use
                    our Website. CATALITZA places on your device only a session-ID cookie. The
                    cookie is used to ensure that the source of a request to a server is the same as
                    the one through which log-in occurred. Cookies allow us to recognize whether you
                    have used the Service before and it helps us to store your preferences and other
                    information.
                    <br />
                    <br />
                    Most of the web browsers allow Cookies. Without fully allowing Cookies, not all
                    of the functions of the Website are available to a Visitor. The allowing or
                    prohibiting Cookies and other similar technologies shall be under the control of
                    a Visitor via the settings of the Visitor’s own web browser. Most browsers allow
                    you to control cookies, including turn off their acceptance by your device,
                    through their settings’ preferences. However, if you turn off the ability of
                    your device to accept cookies, you may lose access to this Website and the
                    Services provided via it.
                    <br />
                    <br />
                    If you prefer that your Personal Data will not be Processed on our Website, you
                    can activate the private browsing feature of your web browser. If you access
                    this Website without changing your browser settings, CATALITZA will assume that
                    you agree to receive the cookies which CATALITZA or third parties place into
                    your device when you use this Website.
                </p>
            </div>

            <div className="point">
                <h2>8. Device permissions</h2>
                <p>
                    For a better experience, while using our Website, CATALITZA may require you to
                    enable Push notifications and give access to the Camera. This permission will
                    only be used to scan QR codes and no data will be retained by CATALITZA.
                </p>
            </div>

            <div className="point">
                <h2>9. Contact</h2>
                <p>
                    CATALITZA would like to make sure you are fully aware of all of your data
                    protection rights, based on the GDPR. Every Visitor can exercise their rights to
                    access, modify, deny or restrict the handling and portability of data, as well
                    as, revoke consent at any time given.
                    <br />
                    <br />
                    If you have any questions or comments about this Privacy Policy, or if you would
                    like to review, delete or update information about you or your preferences,
                    please contact CATALITZA at the following address: catalitza@protonmail.com
                </p>
            </div>

            <div className="point">
                <h2>10. Changes to this Privacy Policy</h2>

                <p>
                    Kindly note that CATALITZA may modify the Privacy Policy from time to time.
                    Please periodically visit this section to review for any changes. The modified
                    policy will be uploaded to Webpage.
                    <br />
                    <br />
                    CATALITZA will notify you of any changes by posting the new Privacy Policy on
                    this page. These changes are effective immediately after they are posted on this
                    page.
                </p>
            </div>

            <p>
                The current version:
                <br />
                Original version from 22.10.2020
            </p>
        </div>
    );
};

export default Privacy;
