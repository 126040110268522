// React utils
import { useForm } from 'react-hook-form';
import { useMyContext } from '../../../contexts/AppContext';

// Components
import { errorModal } from 'utils/modal';

// Services
import { sendEmail } from 'api/services/contact';

import { useTranslation } from 'react-i18next';

// Scss
import './ContactForm.scss';

const ContactForm = () => {
    const { t } = useTranslation();

    const { toogleFormFooter } = useMyContext();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        sendEmail(data)
            .then(() => {
                toogleFormFooter(true);
            })
            .catch((error) => errorModal('Hi ha hagut un error'));
    };

    return (
        <div className="contact__main__container login-box">
            <form onSubmit={handleSubmit(onSubmit)}>
                <input
                    placeholder={t('Correu electrònic')}
                    type="email"
                    {...register('email', { required: true })}
                />
                {errors.email && <span>{t("S'ha d'omplir aquest camp")}</span>}

                <textarea
                    rows={5}
                    placeholder={t('El teu missatge')}
                    {...register('message', { required: true })}
                />
                {errors.message && <span>{t("S'ha d'omplir aquest camp")}</span>}

                <p style={{ color: '#ffb4a1' }}>{t('Enviament segur amb encriptació PGP')}</p>

                <input type="submit" value={t('Enviar')} />
            </form>
        </div>
    );
};

export default ContactForm;
