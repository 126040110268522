import CloseIcon from '@mui/icons-material/Close';
import Logo from 'assets/images/logo.png';
import LangSwitcher from 'modules/shared/components/LangSwitcher';

import './MobileMenu.scss';

const MobileMenu = ({ children, closeMenu }) => {
    return (
        <div className="mobile__menu__container">
            <div className="close__icon__container">
                <img src={Logo} width="75" />
                <CloseIcon className="close__icon" onClick={closeMenu} />
            </div>
            {children}
            <LangSwitcher />
        </div>
    );
};

export default MobileMenu;
