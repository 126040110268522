import { Fragment, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';

import InfoCard from './InfoCard';

import radar from '../../../../../assets/lotties/radar.json';
import phone from '../../../../../assets/lotties/phone.json';
import document from '../../../../../assets/lotties/document.json';
import people from '../../../../../assets/lotties/people.json';

import { useTranslation } from 'react-i18next';

import './InfoStepper.scss';

const InfoStepper = ({ quantity, content }) => {
    const { t } = useTranslation();
    const steps = ['1', '2', '3', '4'];
    const [activeStep, setActiveStep] = useState(0);

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            nextStep();
        }, 10 * 1000);

        return () => clearTimeout(timer);
    }, [activeStep]);

    const nextStep = () => {
        activeStep === steps.length - 1 ? setActiveStep(0) : setActiveStep(activeStep + 1);
    };

    return (
        <div onTouchMove={nextStep} className="infosteper__container">
            <Box sx={{ width: '100%' }}>
                <div>
                    <Fragment>
                        {activeStep === 0 && (
                            <InfoCard
                                icon={radar}
                                title={`01.${t('Serveis de SCV')}`}
                                subtitle={t("Inspeccions d'espais")}
                                text={t(
                                    "Inspecció i securització d'espais físics (edificis, sales o vehicles). Seguiment i manteniment de la seguretat dels espais."
                                )}
                            />
                        )}

                        {activeStep === 1 && (
                            <InfoCard
                                icon={phone}
                                title={`02.${t('Serveis de SCV')}`}
                                subtitle={t('Comunicacions')}
                                text={t(
                                    'Dispositius de comunicació segura, auditories i implantació de procediments.'
                                )}
                            />
                        )}

                        {activeStep === 2 && (
                            <InfoCard
                                icon={people}
                                title={`03.${t('Serveis de SCV')}`}
                                subtitle={t('Eyedetect')}
                                text={t(
                                    'Disposem de la tecnologia més avançada per validar la confiança en equips humans.'
                                )}
                            />
                        )}

                        {activeStep === 3 && (
                            <InfoCard
                                icon={document}
                                title={`04.${t('Serveis de SCV')}`}
                                subtitle={t('Infraestructura')}
                                text={t(
                                    'Assessorem en la creació d’infraestructures mercantils i tecnològiques adaptades als teus requeriments de seguretat.'
                                )}
                            />
                        )}
                    </Fragment>
                </div>
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepButton
                                className="step__buton"
                                onClick={handleStep(index)}
                                sx={{ color: 'red' }}
                            ></StepButton>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        </div>
    );
};

export default InfoStepper;
