// React utils
import { useEffect, useState } from 'react';

// Components
import UserItem from './components/UserItem';
import AddUser from './components/AddUser';

// Services
import { getUsers } from 'api/services/auth';
import CustomAccordion from 'modules/shared/components/CustomAccordion';

const UsersCMS = () => {
    const [users, setUsers] = useState(null);

    useEffect(() => {
        getUsers()
            .then((response) => setUsers(response))
            .catch((error) => {
                console.error(error);
            });
    }, []);

    console.log(users);

    return (
        <div className="userscms__container">
            <h1 style={{ marginBottom: '30px' }}>Gestió dels usuaris</h1>

            <CustomAccordion title={'Afegeix un nou usuari'}>
                <AddUser />
            </CustomAccordion>

            {users && users.map((e, key) => <UserItem user={e} key={key} />)}
        </div>
    );
};

export default UsersCMS;
