import React from 'react';
import { useTranslation } from 'react-i18next';

import './LangSwitcher.scss';

const LangSwitcher = () => {
    const { i18n } = useTranslation();

    return (
        <div className="langswitcher__container">
            <h2 onClick={() => i18n.changeLanguage('ca')}>CA</h2>
            <h2>/</h2>
            <h2 onClick={() => i18n.changeLanguage('en')}>EN</h2>
            <h2>/</h2>
            <h2 onClick={() => i18n.changeLanguage('es')}>ES</h2>
        </div>
    );
};
export default LangSwitcher;
