import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMyContext } from 'contexts/AppContext';
import { useMediaQuery } from 'react-responsive';

import ContactForm from 'modules/shared/components/ContactForm';
import CustomLottie from 'modules/shared/components/Lottie';
import contact from 'assets/lotties/contact.json';
import successform from 'assets/lotties/successform.json';

import Logo from 'assets/images/logo-extra-large.png';

import { Flip } from 'react-awesome-reveal';

import { useTranslation } from 'react-i18next';

import './Footer.scss';

const Footer = () => {
    const { t } = useTranslation();
    const { formSended } = useMyContext();
    const [actualYear, setActualYear] = useState(null);

    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

    useEffect(() => {
        setYear();
    }, []);

    const setYear = () => {
        let year = new Date().getFullYear();
        setActualYear(year);
    };

    return (
        <div className="footer__main__container" id="contacta">
            <div className="footer__first__row row">
                <div className="footer__contact__form">
                    {formSended ? (
                        <div className="success__message__form">
                            <Flip>
                                <CustomLottie
                                    lottie={successform}
                                    size={isMobile ? '300' : '500'}
                                />
                            </Flip>
                            <div className="message__container">
                                <h1 className="section__title">{t('Contacta amb nosaltres')}</h1>
                                <h2>{t('Missatge enviat correctament')}</h2>
                                <h3>{t('Et contactarem en breus')}</h3>
                            </div>
                        </div>
                    ) : (
                        <Fragment>
                            {!isMobile && <CustomLottie lottie={contact} size="500" />}
                            <div className="contact__container">
                                <div className="contact__header">
                                    <h1 className="section__title">
                                        {t('Contacta amb nosaltres')}
                                    </h1>
                                    {isMobile && <CustomLottie lottie={contact} size="300" />}
                                    {/* <h3>
                                        L'enviament és fa amb encriptació segura amb tecnologia PGP
                                    </h3> */}
                                </div>
                                <ContactForm />
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
            <div className="footer__second__row row">
                <div className="footer__logo">
                    <img src={Logo} alt="" />
                </div>
                <div className="footer__menu">
                    <ul>
                        <Link to="/politica-de-privacitat">
                            <li>{t('Política de privacitat')}</li>
                        </Link>
                        <Link to="/politica-de-galetes">
                            <li>{t('Política de galetes')}</li>
                        </Link>
                        <Link to="/avis-legal">
                            <li>{t('Avís legal')}</li>
                        </Link>
                    </ul>
                </div>
            </div>
            <div className="footer__third__row row">
                <div className="footer__copyright">
                    <p>
                        {' '}
                        {t('Tots els drets reservats')} © {actualYear && actualYear}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
