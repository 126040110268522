import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';

const formatOptions = (array) => {
    let newArray = [];
    array.map((e) => {
        newArray.push({ value: e, label: e });
    });

    return newArray;
};

const SelectTags = ({ selectedTag, setSelectedTags, options, newMessage, ...rest }) => {
    const [formatedOptions, setFormatedOptions] = useState(null);
    const [formatedSelecteds, setFormatedSelecteds] = useState(null);

    useEffect(() => {
        if (options) {
            setFormatedOptions(formatOptions(options));
            console.log('adios');
        }

        if (selectedTag) {
            console.log('hola');
            setFormatedSelecteds(formatOptions(selectedTag));
        }

        console.log(selectedTag);
    }, [selectedTag, options]);

    return (
        <div className="tagSelector">
            {formatedOptions && (
                <CreatableSelect
                    defaultValue={formatedSelecteds}
                    onChange={setSelectedTags}
                    options={formatedOptions}
                    style={{ marginTop: '20px' }}
                    noOptionsMessage={() => newMessage}
                    formatCreateLabel={(userInput) => `Afegir '${userInput}'`}
                    {...rest}
                />
            )}
        </div>
    );
};

export default SelectTags;
