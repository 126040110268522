import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Zoom } from 'react-awesome-reveal';
import { NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import './Menu.scss';

const Menu = () => {
    const { t } = useTranslation();
    const activeClass = 'active-link';
    return (
        <div className="menu__container">
            <ul className="menu__elements">
                <Zoom cascade duration={400}>
                    <NavLink
                        to="/"
                        className={({ isActive }) => (isActive ? activeClass : undefined)}
                    >
                        <li>{t('Inici')}</li>
                    </NavLink>
                    <NavLink
                        to="/inspeccions"
                        className={({ isActive }) => (isActive ? activeClass : undefined)}
                    >
                        <li>{t('Inspeccions')}</li>
                    </NavLink>
                    <NavLink
                        to="/comunicacions"
                        className={({ isActive }) => (isActive ? activeClass : undefined)}
                    >
                        <li>{t('Comunicacions')}</li>
                    </NavLink>
                    <NavLink
                        to="/eyedetect"
                        className={({ isActive }) => (isActive ? activeClass : undefined)}
                    >
                        <li>{t('Eyedetect')}</li>
                    </NavLink>
                    <NavLink
                        to="/blog"
                        className={({ isActive }) => (isActive ? activeClass : undefined)}
                    >
                        <li>{t('Blog')}</li>
                    </NavLink>
                </Zoom>
            </ul>
        </div>
    );
};

export default Menu;
