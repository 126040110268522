import { editUser, signup } from 'api/services/auth';
import React from 'react';
import { useForm } from 'react-hook-form';
import './AddUser.scss';
import InputField from '../../BlogCMS/components/InputField';
import { isEmail, isStrongPwd } from 'utils/regex';
import { doneModal, errorModal } from 'utils/modal';
import { useNavigate } from 'react-router-dom';

const EditUser = ({ userId }) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = (data) => {
        const credentials = { id: userId, password: data.password };

        console.log(credentials);

        editUser(credentials)
            .then((response) => {
                console.log(response);
                doneModal("S'ha modificat la contrasenya correctament");
                setTimeout(() => {
                    navigate('/badulake', { replace: true });
                }, 2500);
            })
            .catch((error) => {
                console.error(error);
                errorModal('Hi ha hagut un error');
            });
    };

    console.log(userId);

    return (
        <div className="adduser__container">
            <div className="reacthookform__container">
                <form onSubmit={handleSubmit(onSubmit)} style={{ margin: '50px 0' }}>
                    <InputField
                        type="password"
                        name="password"
                        placeholder="Contrasenya"
                        errors={errors.password || null}
                        autoComplete="current-password"
                        {...register('password', {
                            required: "S'ha d'omplir aquest camp",
                            pattern: {
                                value: isStrongPwd,
                                message:
                                    'La contrasenya ha de contenir mínim 12 caràcters, entre ells una lletra minúscula, una majúscula i un número',
                            },
                        })}
                    />

                    <InputField
                        type="password"
                        name="repeatpassword"
                        placeholder="Repeteix la contrasenya"
                        errors={errors.repeatpassword || null}
                        autoComplete="current-password"
                        {...register('repeatpassword', {
                            required: "S'ha d'omplir aquest camp",
                            validate: (val) => {
                                if (watch('password') != val) {
                                    return 'La contrasenya no coincideix';
                                }
                            },
                        })}
                    />

                    <p style={{ color: 'red' }}>{errors.login?.message}</p>

                    <button>Modificar contrasenya</button>
                </form>
            </div>
        </div>
    );
};

export default EditUser;
