import { createContext, useContext } from 'react';

const AppContext = createContext();

export const AppContextProvider = AppContext.Provider;
export const AppContextConsumer = AppContext.Consumer;

export const useMyContext = () => {
    const context = useContext(AppContext);
    return context;
};

export default AppContext;
