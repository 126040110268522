import { createElement } from 'react';
import { Zoom } from 'react-awesome-reveal';

import './SingleFeature.scss';

const SingleFeature = ({ content }) => {
    return (
        <div className="single__feature__container">
            <Zoom cascade>
                <div className="feature__icon__container">
                    {createElement(content.icon, {
                        className: 'iconito__active',
                    })}
                </div>
                <div className="feature__title__container">
                    <h2>{content.title}</h2>
                    <p>{content.text}</p>
                </div>
                <div className="feature__text__container"></div>
            </Zoom>
        </div>
    );
};

export default SingleFeature;
