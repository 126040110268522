import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const colors = {
    red: '#804df6',
    black: '#000000',
};

export const doneModal = (message) => {
    MySwal.fire({
        title: <strong>{message}</strong>,
        icon: 'success',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
        color: colors.black,
    });
};

export const errorModal = (error) => {
    Swal.fire({
        icon: 'error',
        text: error,
        confirmButtonText: "D'acord",
        confirmButtonColor: '#000000',
        iconColor: colors.red,
    });
};

export const confirmationModal = (actionText, action) => {
    Swal.fire({
        icon: 'warning',
        title: `Vols ${actionText}?`,
        showDenyButton: true,
        confirmButtonText: `Sí, ${actionText}`,
    }).then((result) => {
        if (result.isConfirmed) {
            action();
        }
    });
};
