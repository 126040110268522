import client from '../client';

export const getAllMessages = () => {
    return client.get('email');
};

export const decryptPGP = (message, password) => {
    return client.post('email/decrypt', { message, password });
};

export const sendEmail = (data) => {
    return client.post('email/contactform', data);
};
