import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import SyncLockOutlinedIcon from '@mui/icons-material/SyncLockOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SingleFeature from './SingleFeature';

import { useTranslation } from 'react-i18next';

import './Features.scss';

const Features = () => {
    const { t } = useTranslation();

    const data = [
        {
            icon: PhoneIphoneOutlinedIcon,
            title: t('Dispositius'),
            text: t('Amb mòbils que garanteixen la privacitat de les comunicacions'),
        },
        {
            icon: PolicyOutlinedIcon,
            title: t('Auditories'),
            text: t('Identifiquem els riscos i amenaces de les vostres comunicacions'),
        },
        {
            icon: SyncLockOutlinedIcon,
            title: t('Protocols'),
            text: t('Establim una nova forma de comunicar-vos segura i privada'),
        },
        {
            icon: SchoolOutlinedIcon,
            title: t('Formació'),
            text: t('Formem i sensibilitzem totes les persones involucrades'),
        },
    ];

    return (
        <div className="features__container">
            {data && data.map((e, key) => <SingleFeature content={e} key={key} />)}
        </div>
    );
};

export default Features;
