// Scss
import './Card.scss';

const Card = ({ name, children }) => {
    return (
        <div className="card">
            <h2>{name}</h2>
            <div className="card__children">{children}</div>
        </div>
    );
};

export default Card;
