import client, { setAuthorizationHeader, removeAuthorizationHeader } from 'api/client';
import storage, { session } from 'utils/storage';

export const login = (credentials) => {
    return client
        .post('/auth/signin', credentials)
        .then(({ accessToken }) => {
            setAuthorizationHeader(accessToken);
            credentials.remember
                ? storage.set('auth', accessToken)
                : session.set('auth', accessToken);
            return { status: 200 };
        })
        .catch((error) => {
            console.log(error);
            return { status: 402 };
        });
};

export const signup = (data) => {
    return client.post('/auth/signup', data);
};

export const logout = () =>
    Promise.resolve().then(() => {
        removeAuthorizationHeader();
        storage.remove('auth');
        session.remove('auth');
    });

export const getUsers = () => {
    return client.get('/auth');
};

export const editUser = (data) => {
    return client.post('/auth/changepassword', data);
};
