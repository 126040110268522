import { Zoom, Fade } from 'react-awesome-reveal';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import CustomLottie from '../../../../shared/components/Lottie';

import { useMediaQuery } from 'react-responsive';

import { useTranslation } from 'react-i18next';

import './InfoCard.scss';

const InfoCard = ({ icon, title, subtitle, text }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });

    const { t } = useTranslation();

    return (
        <div className="infocard__main__container">
            <div className="icon__container">
                <Zoom triggerOnce>
                    <CustomLottie lottie={icon} size={isMobile ? 300 : 400} />
                </Zoom>
            </div>
            <div className="text__container">
                <Fade cascade duration={750} triggerOnce>
                    <h2>{title}</h2>
                    <h3>{subtitle}</h3>
                    <p>{text}</p>
                    <div className="contact__button__interior">
                        <AnchorLink href="#contacta">
                            <button>{t("Contacta'ns")}</button>
                        </AnchorLink>
                    </div>
                </Fade>
            </div>
        </div>
    );
};

export default InfoCard;
