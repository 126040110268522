import HeaderBanner from '../../../shared/components/HeaderBanner';
import Comunications from '../../../../assets/lotties/comunications.json';
import AnchorLink from 'react-anchor-link-smooth-scroll';

// import SelectFeature from './components/SelectFeature';
import Features from './components/Features';

import { useTranslation } from 'react-i18next';

import './ComunicacionsPage.scss';

const ComunicacionsPage = () => {
    const { t } = useTranslation();

    return (
        <div className="comunicacions__main__container">
            <HeaderBanner
                title={t('Comunicacions')}
                subtitle={t('Fem del mòbil una eina de comunicació privada')}
                lottie={Comunications}
                anchor="comunicacions"
            />

            <div className="page__container" id="comunicacions">
                <div className="title__container">
                    <h2>{t('Com ho aconseguim')}</h2>
                </div>
                {/* <SelectFeature /> */}
                {<Features />}

                <div className="contact__button__interior">
                    <AnchorLink href="#contacta">
                        <button>{t("Demana'ns més informació")}</button>
                    </AnchorLink>
                </div>
            </div>
        </div>
    );
};

export default ComunicacionsPage;
