import { useEffect } from 'react';
import { Zoom } from 'react-awesome-reveal';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import CustomLottie from './Lottie';
import Arrow from '../components/Arrow';

import { useMediaQuery } from 'react-responsive';
import './HeaderBanner.scss';

const HeaderBanner = ({ title, subtitle, lottie, anchor }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
    return (
        <div className="headerbanner__main__container">
            <div className="title__container">
                <h1>{title}</h1>
            </div>
            <div className="lottie__container">
                <Zoom triggerOnce>
                    <CustomLottie lottie={lottie} size={isMobile ? 300 : 400} />
                </Zoom>
            </div>
            <div className="subtitle__container">
                <h2>{subtitle}</h2>
            </div>
            <div className="arrow__container">
                <AnchorLink href={`#${anchor}`}>
                    <Arrow />
                </AnchorLink>
            </div>
        </div>
    );
};

export default HeaderBanner;
