// React utils
import { useMyContext } from 'contexts/AppContext';
import { Link } from 'react-router-dom';

// Utils
import { htmlToPlain } from 'utils/strings';
import { isoToShort } from 'utils/time';

// Icons
import EditIcon from '@mui/icons-material/Edit';

// i18n
import { useTranslation } from 'react-i18next';

// Scss
import './CardBlog.scss';

const CardBlog = ({ content }) => {
    const { t } = useTranslation();

    const { isLogged } = useMyContext();

    return (
        <div className="preview__post__container">
            <Link to={`/blog/${content.id}`}>
                <div className="preview__post__image">
                    <img src={content.image} alt="" />
                </div>
                <div className="preview__post__taxonomy">
                    <h3>
                        {content.categories.toString().toUpperCase()} -{' '}
                        {isoToShort(content.createdAt)}
                    </h3>
                </div>
                <div className="preview__post__title">
                    <h2>{content.title}</h2>
                </div>
                <div className="preview__post__text">
                    <p>
                        {htmlToPlain(content.content).slice(0, 150)}
                        {content.content.length > 149 && '...'}
                    </p>
                </div>
                <div className="preview__post__button contact__button__interior">
                    {isLogged && (
                        <div className="cms__action__buttons">
                            <Link to={`/badulake/blog/editor/${content.id}`}>
                                <EditIcon className="action__button" />
                            </Link>
                        </div>
                    )}
                    <button>{t('Llegir més')}</button>
                </div>
            </Link>
        </div>
    );
};

export default CardBlog;
