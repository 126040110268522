import { getAllMessages } from 'api/services/contact';
import CustomAccordion from 'modules/shared/components/CustomAccordion';
import { useEffect, useState } from 'react';
import EmailMessage from './components/EmailMessage';

const ContactCMS = () => {
    const [messages, setMessages] = useState(null);

    useEffect(() => {
        getAllMessages()
            .then((response) => setMessages(response.reverse()))
            .catch((error) => console.log(error));
    }, []);

    console.log(messages);

    return (
        <div className="contactcms__container">
            <h1>Missatges del formulari de contacte</h1>

            <div className="messages__container">
                {messages && messages.map((e, key) => <EmailMessage message={e} key={key} />)}
            </div>
        </div>
    );
};

export default ContactCMS;
