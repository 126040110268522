// React utils
import { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import Header from './components/Header';
import Footer from './components/Footer';
import HeaderCMS from './components/HeaderCMS';

// SCSS
import './Layout.scss';

const Layout = ({ children }) => {
    const location = useLocation();
    const [cms, setCms] = useState(false);

    useEffect(() => {
        const isCms = () => {
            const path = location.pathname.split('/')[1];
            const isCms = path === 'badulake' || path === 'emart';

            return isCms;
        };

        setCms(isCms());
    }, [location]);

    if (cms) {
        return (
            <div className="layout__cms">
                <HeaderCMS />
                <div className="body__cms">{children}</div>
            </div>
        );
    }

    return (
        <div className="layout__website">
            <Header />
            <div className="background">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            {children}
            <Footer />
        </div>
    );
};

export default Layout;
