import { useState, useEffect } from 'react';
import { getPosts } from 'api/services/posts';

const UseLoadPosts = () => {
    const [posts, setPosts] = useState(null);

    useEffect(() => {
        const loadPosts = () => {
            getPosts()
                .then((response) => setPosts(response))
                .catch((error) => console.log('Error fetching posts:', error));
        };

        loadPosts();
    }, []);

    return posts;
};

export default UseLoadPosts;
