import AnchorLink from 'react-anchor-link-smooth-scroll';
import Arrow from '../../../../../shared/components/Arrow';
import InfoStepper from '../../components/InfoStepper';

import './Info.scss';

const Info = () => {
    return (
        <div className="info__main__container">
            <InfoStepper />
            <AnchorLink href="#contacta">
                <Arrow />
            </AnchorLink>
        </div>
    );
};

export default Info;
