// React utils
import { Link } from 'react-router-dom';

// Icons
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import GridViewIcon from '@mui/icons-material/GridView';

// Context
import { useMyContext } from 'contexts/AppContext';

// Images
import logo from 'assets/images/logo.png';

// Scss
import './HeaderCMS.scss';

const HeaderCMS = () => {
    const { userLogout } = useMyContext();

    return (
        <div className="headercms__container">
            <div className="logo">
                <img src={logo} alt="" />
            </div>
            <div className="cms__menu">
                <Link to={'/'}>
                    <HomeIcon className="home__icon" />
                </Link>
                <Link to={'/badulake'}>
                    <GridViewIcon className="home__icon" />
                </Link>
                <LogoutIcon className="home__icon" onClick={userLogout} />
            </div>
        </div>
    );
};

export default HeaderCMS;
