// React utils
import * as React from 'react';
import TextField from '@mui/material/TextField';

// SCSS
import './InputField.scss';

const InputField = React.forwardRef(
    ({ placeholder, type, value, name, errors, children, ...rest }, ref) => {
        const errorStyle = {
            color: 'red',
            fontSize: '0.75rem',
            lineHeight: '10px',
            margin: '10px 0px',
        };

        return (
            <div className="inputfield">
                <TextField
                    label={placeholder}
                    type={type}
                    value={value}
                    name={name}
                    variant={'filled'}
                    fullWidth
                    ref={ref}
                    {...rest}
                />
                {children}
                {errors && <p style={errorStyle}>{errors.message}</p>}
            </div>
        );
    }
);

export default InputField;
