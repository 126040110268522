import client from '../client';

export const getPosts = () => {
    return client.get('posts');
};

export const getSinglePost = (id) => {
    return client.get(`posts/${id}`);
};

export const getCategories = () => {
    return client.get('categories');
};

export const newPost = (data) => {
    return client.post('posts/new', data);
};

export const updatePost = (data) => {
    return client.patch('posts/update', data);
};

export const deletePost = (id) => {
    console.log(id);
    return client.post('/posts/delete', { id });
};

export const uploadFile = (data) => {
    return client.post('/posts/upload', data);
};
