// React utils
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

// Components
import App from './App';

// Utils
import { setAuthorizationHeader } from 'api/client';
import storage, { session } from 'utils/storage';

// i18n
import 'i18n/i18n';

// Css
import './index.css';

const accesToken = storage.get('auth') || session.get('auth');
setAuthorizationHeader(accesToken);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.Suspense fallback="Loading...">
        <BrowserRouter>
            <App logged={!!accesToken} />
        </BrowserRouter>
    </React.Suspense>
);

reportWebVitals();
