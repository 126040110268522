import './Legal.scss';

const Cookies = () => {
    return (
        <div className="legal__page__container">
            <h1>Política de galetes</h1>

            <div className="point">
                <h2>1. TITULARITAT</h2>
                <p>
                    La pàgina web: https://scv.cat/ (“WEB”), titularitat de CATALITZA OÜ
                    (“CATALITZA”) utilitza la tecnologia denominada “cookies” o galetes.
                </p>
            </div>

            <div className="point">
                <h2>2. DEFINICIÓ I FUNCIÓ DE LES COOKIES:</h2>
                <p>
                    Les cookies són petits arxius que s’envien pel navegador i emmagatzemen al disc
                    dur de l’ordinador de l’usuari a través del seu navegador quan es connecta a una
                    web. Les cookies permeten a una pàgina web, entre altres coses, emmagatzemar i
                    recuperar informació sobre els hàbits de navegació d’un usuari o del seu equip
                    i, depenent de la informació que continguin i de la manera com utilitzi el seu
                    equip, es poden utilitzar per a reconèixer a l’usuari.
                </p>
            </div>

            <div className="point">
                <h2>3. ÚS I FINALITAT DE LES COOKIES:</h2>
                <p>
                    La pàgina web de CATALITZA (en endavant, la “WEB”) utilitza la tecnologia
                    denominada cookies (galetes) amb la finalitat de permetre la comunicació de
                    forma adequada entre l’equip de l’usuari i la xarxa, i per permetre a l’usuari
                    accedir als continguts de la WEB.
                </p>
            </div>

            <div className="point">
                <h2>4. COOKIES UTILITZADES A LA WEB I PROTECCIÓ DE DADES PERSONALS:</h2>
                <p>
                    A través de la WEB de CATALITZA no es recullen dades de caràcter personal dels
                    usuaris sense el seu consentiment. La WEB no utilitza cookies per a recollir
                    informació dels usuaris, ni registra les adreces IP d’accés.
                </p>

                <p>
                    La WEB únicament utilitza cookies pròpies, amb finalitat tècnica, i de sessió.
                    Es a dir, aquelles cookies que permeten a l’usuari la navegació a través de la
                    WEB, la utilització de les diferents opcions i pestanyes que existeixen a la
                    WEB, perquè l’usuari pugui accedir a tots els continguts. Les cookies s’eliminen
                    quan l’usuari finalitza la sessió a la WEB.
                </p>

                <p>
                    Les cookies utilitzades per la WEB són cookies per permetre l’entrada de
                    l’usuari (de sessió i entrada de l’usuari per permetre-li completar el formulari
                    en línia), per autenticació o identificació de l’usuari (només de sessió), per
                    seguretat, per sessió de reproductor multimèdia, per equilibrar càrregues o
                    personalització de la interfície d’usuari.
                </p>
            </div>

            <div className="point">
                <h2>5. COOKIES DE TERCERS:</h2>
                <p>
                    La WEB pot contenir enllaços a pàgines web de tercers quines polítiques de
                    privacitat i cookies són alienes a les de CATALITZA. CATALITZA no serà
                    responsable de la utilització i gestió de cookies que facin els propietaris
                    d’altres pàgines web. Si navega per internet vostè pot acceptar o rebutjar les
                    cookies de tercers des de les opcions de configuració del seu navegador.
                </p>
            </div>

            <div className="point">
                <h2>6. ACTUALITZACIÓ I MODIFICACIÓ:</h2>
                <p>
                    CATALITZA pot modificar aquesta informació sobre cookies de la WEB quan sigui
                    necessari, per la qual cosa li recomanem revisar aquesta Política de Cookies de
                    manera periòdica. Tanmateix, quan es produeixin canvis significatius a la
                    Política de Cookies, CATALITZA ho informarà puntualment a la WEB.
                </p>
            </div>
        </div>
    );
};

export default Cookies;
