// React utils
import { Controller, useForm } from 'react-hook-form';
import { useState, useEffect, useReducer } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Components
import QuillEditor from './QuillEditor';
import { confirmationModal, doneModal, errorModal } from 'utils/modal';
import SelectTags from './SelectTags';
import { Button, TextField } from '@mui/material';

// Services
import { deletePost, getCategories, getSinglePost, newPost, updatePost } from 'api/services/posts';

// Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// SCSS
import './PostForm.scss';

const PostForm = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const [goBack, setGoBack] = useState(false);

    const [postLoaded, setPostLoaded] = useState(null);

    const [htmlRendered, setHtmlRendered] = useState(null);
    const [tags, setTags] = useState(null);
    const [categoriesFetched, setCategoriesFetched] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [featuredImage, setFeaturedImage] = useState(null);

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const errorStyle = {
        color: 'red',
        fontSize: '0.75rem',
        lineHeight: '10px',
        margin: '10px 0px',
    };

    useEffect(() => {
        if (id) {
            getSinglePost(id)
                .then((data) => {
                    setTags(data.categories);
                    setPostLoaded(data);
                    reset(data);
                    setHtmlRendered(data.content);
                    setPreviewImage(data.image);
                })
                .catch((error) => {
                    console.log(error);
                    goBack();
                });
        }

        getCategories().then((response) => setCategoriesFetched(response));

        return () => {};
    }, [id]);

    const handleImage = (event) => {
        const image = event.target.files[0];

        setPreviewImage(URL.createObjectURL(image));
        setFeaturedImage(image);
    };

    const handleDeletePost = () => {
        const delPost = () => {
            deletePost(id)
                .then(() => doneModal('Tot ha anat bé!'))
                .then(() => setGoBack(true))
                .catch((error) => {
                    console.log(error);
                    errorModal('Alguna cosa ha anat malament');
                });
        };

        confirmationModal('borrar el post', delPost);
    };

    const handleTags = (tags) => {
        const newArray = [];
        tags.map((e) => newArray.push(e.value));

        setTags(newArray);
    };

    const onSubmit = async (data) => {
        data.content = htmlRendered;

        delete data.categories;

        if (featuredImage) {
            data.image = featuredImage;
        }

        const form = new FormData();

        Object.entries(data).forEach(([key, value]) => {
            form.append(key, value);
        });

        for (const a of tags) {
            form.append('categories[]', a);
        }

        try {
            if (postLoaded) {
                await updatePost(form);
            } else {
                await newPost(form);
            }

            doneModal('Tot ha anat bé!');
            setGoBack(true);
        } catch (error) {
            console.log(error);
            errorModal('Alguna cosa ha anat malament');
        }
    };

    if (goBack) {
        setTimeout(() => {
            return navigate('/badulake', { replace: true });
        }, 3500);
    }

    return (
        <div className="reacthookform__container">
            {postLoaded && (
                <ArrowBackIcon
                    onClick={() => setGoBack(true)}
                    sx={{ fontSize: '50px', marginBottom: '20px' }}
                />
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input__field">
                    <Controller
                        control={control}
                        name="file"
                        rules={{
                            required: postLoaded ? false : "S'ha d'omplir aquest camp",
                        }}
                        render={({ field }) => (
                            <Button
                                variant="contained"
                                component="label"
                                onChange={handleImage}
                                className="file__input"
                            >
                                {previewImage
                                    ? 'Canviar imatge destacada'
                                    : 'Afegir imatge destacada'}
                                <input
                                    type="file"
                                    hidden
                                    accept=".jpg, .jpeg, .png, .gif"
                                    {...field}
                                />
                            </Button>
                        )}
                    />
                    {errors.file && <span style={errorStyle}>This field is required</span>}
                    <img src={previewImage} />
                </div>

                <div className="input__field">
                    <Controller
                        control={control}
                        name="title"
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field }) => (
                            <TextField label="Títol" variant="filled" fullWidth {...field} />
                        )}
                    />
                    {errors.title && <span style={errorStyle}>Has d'omplir aquest camp</span>}
                </div>

                <div className="input__field">
                    <QuillEditor value={htmlRendered} setValue={setHtmlRendered} />
                </div>

                <div className="input__field">
                    {postLoaded && (
                        <SelectTags
                            selectedTag={tags}
                            setSelectedTags={handleTags}
                            options={categoriesFetched}
                            placeholder="Selecciona una categoria"
                            newMessage={'Escriu per afegir un idioma'}
                            isMulti
                        />
                    )}

                    {!postLoaded && (
                        <SelectTags
                            selectedTag={null}
                            setSelectedTags={handleTags}
                            options={categoriesFetched}
                            placeholder="Selecciona una categoria"
                            newMessage={'Escriu per afegir un idioma'}
                            isMulti
                        />
                    )}
                </div>

                <div className="post__buttons">
                    <button>{postLoaded ? 'Actualitzar post' : 'Crear post'}</button>
                    {postLoaded && (
                        <button
                            type="button"
                            className="post__delete__button"
                            onClick={handleDeletePost}
                        >
                            Eliminar post
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

export default PostForm;
