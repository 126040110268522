// React utils
import { useState, Fragment } from 'react';

// Utils
import { errorModal } from 'utils/modal';
import Swal from 'sweetalert2';
import { isoToShort } from 'utils/time';

// Services
import { decryptPGP } from 'api/services/contact';

// Icons
import LockOpenIcon from '@mui/icons-material/LockOpen';
// SCSS
import './EmailMessage.scss';

const EmailMessage = ({ message }) => {
    const [messageDecrypted, setMessageDecrypted] = useState(null);

    const decryptMessage = () => {
        Swal.fire({
            title: 'Introdueix la contrasenya',
            input: 'password',
            inputAttributes: {
                autocapitalize: 'off',
            },
            showCancelButton: true,
            confirmButtonText: 'Desencriptar',
            showLoaderOnConfirm: true,
            preConfirm: (input) => {
                // return fetch(`//api.github.com/users/${login}`)
                //     .then((response) => {
                //         if (!response.ok) {
                //             throw new Error(response.statusText);
                //         }
                //         return response.json();
                //     })
                //     .catch((error) => {
                //         Swal.showValidationMessage(`Request failed: ${error}`);
                //     });

                return decryptPGP(message.message, input)
                    .then(({ decrypted }) => {
                        if (decrypted) {
                            console.log(decrypted);
                            return decrypted;
                        }
                    })
                    .catch((error) => {
                        throw new Error('Hi ha hagut un error');
                    });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        })
            .then((result) => {
                if (result.isConfirmed) {
                    setMessageDecrypted(result.value);
                }
            })
            .catch((error) => errorModal(error));
    };

    return (
        <div className="emailmessage__container">
            <h2>{message.id}</h2>
            <h3>{isoToShort(message.createdAt)}</h3>
            {messageDecrypted ? (
                <div className="message">
                    <p>{message.email}</p>
                    <p>{messageDecrypted}</p>
                </div>
            ) : (
                <button onClick={decryptMessage}>
                    <LockOpenIcon />
                    Desencriptar
                </button>
            )}
        </div>
    );
};

export default EmailMessage;
