import { useEffect, useRef } from 'react';

import Superhero from './sections/Superhero/Superhero';
import Info from './sections/Info/Info';

import './Home.scss';

const Home = () => {
    return (
        <div className="home__container">
            <section>
                <Superhero />
            </section>
            <section id="informacio">
                <Info />
            </section>
        </div>
    );
};

export default Home;
