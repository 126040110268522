import { Player } from '@lottiefiles/react-lottie-player';

const CustomLottie = ({ lottie, size }) => {
    return (
        <Player
            autoplay
            loop
            src={JSON.parse(JSON.stringify(lottie))}
            style={{ height: `${size}px`, width: `${size}px` }}
            className="custom__lottie"
        ></Player>
    );
};

export default CustomLottie;
