// React utils
import { useState } from 'react';

// Components
import EditUser from './EditUser';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// SCSS
import './UserItem.scss';

const UserItem = ({ user }) => {
    const [editMode, setEditMode] = useState(false);

    console.log(user);

    return (
        <div className="useritem__container">
            <div className="useritem__info">
                <div className="useritem__header">
                    <AccountCircleIcon />
                    <h2>{user.email}</h2>
                </div>
                {editMode && <EditUser userId={user.id} />}
            </div>

            {!editMode && (
                <button className="useritem__edit__button" onClick={() => setEditMode(true)}>
                    <EditIcon /> Editar usuari
                </button>
            )}
        </div>
    );
};

export default UserItem;
