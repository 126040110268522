import { RWebShare } from 'react-web-share';
import CustomLottie from './Lottie';
import ShareLottie from '../../../assets/lotties/share.json';
import './SocialShare.scss';
import { useTranslation } from 'react-i18next';

const SocialShare = ({ text, url, title }) => {
    const { t } = useTranslation();

    return (
        <div className="rwebshare">
            <RWebShare
                data={{
                    text,
                    url,
                    title,
                }}
                closeText="Tancar"
                onClick={() => console.log('shared successfully!')}
            >
                {/* <button>
                    Fes correr la veu <CampaignIcon className="campaign-icon" />
                </button> */}

                <button>
                    {t('Fes correr la veu')}
                    <span>
                        <CustomLottie lottie={ShareLottie} size="75" />
                    </span>
                </button>
            </RWebShare>
        </div>
    );
};

export default SocialShare;
